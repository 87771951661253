import { usePrivateUser } from "hooks/firestore/simple/usePrivateUser"
import { useShop } from "hooks/firestore/simple/useShop"
import { useUser } from "hooks/firestore/simple/useUser"
import { useUrlQuery } from "hooks/localstate/url/useUrlQuery"

export function useB2BStorefront() {
  const user = useUser()
  const privateUser = usePrivateUser()
  const shop = useShop()

  // Check if user is added as a b2b customer in the shop
  const userIsB2BCustomerInCurrentShop = !!shop?.b2bCustomers?.some(c => c.id === user?._ref.id)

  // Check if user has all data filled out
  const userHasAllData = !!privateUser?.b2b?.address && !!privateUser?.b2b?.businessName

  const [b2b] = useUrlQuery("b2b")

  return b2b !== undefined && b2b === "true" && userIsB2BCustomerInCurrentShop && userHasAllData
}
